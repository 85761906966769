<script>
import Swal from "sweetalert2";
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { fetchEquipmentsApi } from "@/api/common";
import Multiselect from "vue-multiselect";
import { getDropDownElementsByTypeApi } from "@/api/common";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
// import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
import SideButtons from '../../../../../../components/side-buttons.vue';

import {
  required, maxLength
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "Equipements",
    meta: [{ name: "description", content: appConfig.description }]
  },
  import: { getDropDownElementsByTypeApi},
  components: {
      Layout, 
      PageHeader,
      Multiselect,
      Dataset,
      DatasetItem,
      // DatasetInfo,
      DatasetPager,
      // DatasetSearch,
      DatasetShow,
      SideButtons
  },
  data() {
    return {
      title: "Equipements",
      uoms: [],
      categories: [],
      types: [],
      brands: [],
      showModal: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      tableData: {},
      equipments: [],
      items: [
        {
          text: "Master Data",
          to: {name:"master_data"},
        },
        {
          text: "referentiels",
          to: {name:"master_data.referentiels"},
        },
        {
          text: "Gestion des Equipements",
          active: true,
        },
      ],
      cols: [
        {
          name: 'Code',
          field: 'code',
          sort: ''
        },
        {
          name: 'Désignation',
          field: 'ref',
          sort: ''
        },
        {
          name: 'Marque',
          field: 'lifebase',
          sort: ''
        },
        {
          name: 'Catégorie',
          field: 'contract',
          sort: ''
        },
        {
          name: 'Type',
          field: 'supplier',
          sort: ''
        },
        // {
        //   name: 'Date ODS',
        //   field: 'date_ods',
        //   sort: ''
        // },
      ],
      equipForm: {
        uuid: "",
        code: "",
        name: "",
        description: "",
        category: "",
        type: "",
        brand: "",
        comments: "",
      },
      getEmit:false,
      info:false,
      add:false,
      disabled:true,
      saveToPending:false,
      confirm:false,
      validate:false,
      edit:false,
      have:false,
      destroy:false,
      estimated:false,
      resetForm:false,
      canEdit :true,
      
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      selectedRows : [],
      selectedIndex : -1,
      active : false,
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ]
    };

    },
    validations: {
      equipForm: {
        name: {required, maxLength:maxLength(64)},
        category: {required},
        type: {required},
        brand: {required},
      },
  },
  watch:{
      add:{
      handler(val){
        if(val){
          this.resetData();
          this.resetForm = false;
          this.disabled  = false;
          // this.canEdit   = true;
        }
      }
    },
    resetForm:{
        handler(val){
            if(val) {
                this.resetData();
                this.disabled=true;
            }
        }
    },
    edit:{
      handler(val){
        if(val){
          this.disabled = false;
          this.have = true;
          this.canEdit = false;
        }
      }
    },
    destroy:{
      handler(val){
        if(val)this.deleteEquipment(this.equipForm.name, this.equipForm.uuid);
      }
    }
  },
  mounted() {
    this.fetchEquipmentsTypes();
    this.fetchEquipmentsCategs();
    this.fetchEquipmentsBrands();
    this.totalRows = this.tableData.length;
    this.fetchEquipmentsList();
  },
  methods: {
    clearForm(){
        this.equipForm.uuid        = "";
        this.equipForm.code        = "";
        this.equipForm.name        = "";
        this.equipForm.description = "";
        this.equipForm.category    = "";
        this.equipForm.type        = "";
        this.equipForm.brand       = "";
        this.equipForm.comments    = "";
    },
    cancelBtn(){
      this.disabled  = true;
      this.have      = false;
      this.canEdit   = true;
      this.edit    = false;
      this.showForm  = false;
      this.resetForm = true;
    },
    selectRow(row,index){
        this.clearForm()
        this.have    = false;
        this.edit    = false;
        this.canEdit = false;
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;

        this.equipForm = {
            uuid: row.uuid,
            code: row.code,
            name: row.name,
            description: row.description,
            category: row.category,
            type: row.type,
            brand: row.brand,
            comments: row.comments,
        }
        this.disabled = true;
        this.canEdit  = false;
      }
    },
    fetchEquipmentsTypes(){
      getDropDownElementsByTypeApi("equipment_types")
        .then((res) => (this.types = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },

    fetchEquipmentsCategs(){
      getDropDownElementsByTypeApi("equipment_categories")
        .then((res) => (this.categories = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },

    fetchEquipmentsBrands(){
      getDropDownElementsByTypeApi("equipment_brands")
        .then((res) => (this.brands = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http.post('/infrastructure/equipments/store', this.equipForm)

      .then((res) => {
          var status = res.data.original.status;
          switch(status){
            case 200: 
              Swal.fire("Félicitations!", res.data.original.msg, "success");
              this.fetchEquipmentsList();
              this.disabled = false;
              this.resetData();
              this.resetForm = true;

              this.canEdit = true;
            break;

            case 500: 
              Swal.fire("Erreur!", res.data.original.msg, "error");
            break;
          }
      })
      .catch((error) => {
          this.$toast.error(error.message);
      }).finally(() => {
      });
    }
    
    },
      fetchEquipmentsList(){
      fetchEquipmentsApi()
      .then(res => (this.equipments = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

     deleteEquipment(deptName, deptUid) {
       var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer l'équipement: " + deptName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/infrastructure/equipments/delete/" + deptUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  _this.$toast.success(res.data.original.msg);
                  _this.fetchEquipmentsList();
                  _this.resetData();
                  _this.resetForm=true;
                  break;

                case 500:
                  _this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    resetData(){
      this.$v.$reset();
      this.canEdit   = true;
      this.clearForm();
      this.disabled = true;
      this.selectedRows = []; this.selectedIndex = -1;
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste Des Rôles  </div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="mb-0 shadow">
                <div class="table-responsive mb-0">
                  <dataset
                    v-slot="{ ds }"
                    :ds-data="equipments"
                  >
                    <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <dataset-search ds-search-placeholder="Search..." />
                    </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="">
                          <table class="table table-hover d-md-table">
                            <thead>
                              <tr>
                                <th v-for="(th) in cols" :key="th.field">
                                  {{ th.name }} 
                                </th>
                              </tr>
                            </thead>
                            <dataset-item tag="tbody">
                              <template #default="{ row, rowIndex }">
                                <tr @click="selectRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                                  <td>{{ row.code }}</td>
                                  <td>{{ row.name }}</td>
                                  <td>{{ row.brand ? row.brand.designation : 'N/A' }}</td>
                                  <td>{{ row.category ? row.category.designation : 'N/A' }}</td>
                                  <td>{{ row.type ? row.type.designation : 'N/A' }}</td>
                                </tr>
                              </template>
                            </dataset-item>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                      
                      <div class="col-md-3 mb-2 mb-md-0">
                        <dataset-show :ds-show-entries="5" />
                      </div>
                      <dataset-pager />
                    </div>
                  </dataset>
                  
                </div>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
  </div>
    </div>
    <div class="row">
      <SideButtons :reset="resetForm"
          :canEdit="canEdit" :select="have" @add="add = $event"  @edit=" edit = $event"  
          @resetEmit="resetForm = $event" @delete=" destroy = $event" />
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <fieldset :disabled="disabled">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg">
                  <div class="form-group">
                    <label for="code">Code </label>
                    <input
                      id="code"
                      v-model="equipForm.code"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                    />
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg">
                  <div class="form-group">
                    <label for="deptName">Désignation *</label>
                    <input
                      id="deptName"
                      v-model="equipForm.name"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.equipForm.name.$error }"
                    />
                    <div v-if="submitted && $v.equipForm.name.$error" class="invalid-feedback">
                      <span v-if="!$v.equipForm.name.required">Le nom est obligatoire.</span>
                      <span v-if="!$v.equipForm.name.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg">
                  <div class="form-group">
                    <label for="deptName">Catégorie *</label>
                    <multiselect
                      v-model="equipForm.category"
                      :searchable="true"
                      track-by="id"
                      label="designation"
                      :options="categories"
                      placeholder="Select category"
                      :allow-empty="false"
                      :class="{ 
                        'disabledMS': disabled,
                        'is-invalid': submitted && $v.equipForm.category.$error }"
                      
                    >
                      <template slot="singleCateg" slot-scope="{ categ }">{{
                        categ.designation
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.equipForm.category.$error" class="invalid-feedback">
                      <span v-if="!$v.equipForm.category.required">Catégorie obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg">
                  <div class="form-group">
                    <label for="deptName">Type *</label>
                    <multiselect
                      v-model="equipForm.type"
                      :searchable="true"
                      track-by="id"
                      label="designation"
                      :options="types"
                      placeholder="Select type"
                      :allow-empty="false"
                      :class="{ 
                        'disabledMS': disabled,
                        'is-invalid': submitted && $v.equipForm.type.$error }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.designation
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.equipForm.type.$error" class="invalid-feedback">
                      <span v-if="!$v.equipForm.type.required">Type obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg">
                  <div class="form-group">
                    <label for="deptName">Marque *</label>
                    <multiselect
                      v-model="equipForm.brand"
                      :searchable="true"
                      track-by="id"
                      label="designation"
                      :options="brands"
                      placeholder="Select brand"
                      :allow-empty="false"
                      :class="{ 
                        'disabledMS': disabled,
                        'is-invalid': submitted && $v.equipForm.brand.$error }"
                    >
                      <template slot="singleBrand" slot-scope="{ brand }">{{
                        brand.designation
                      }}</template>
                    </multiselect>
                    <div v-if="submitted && $v.equipForm.brand.$error" class="invalid-feedback">
                      <span v-if="!$v.equipForm.brand.required">Marque obligatoire.</span>
                    </div>
                  </div>
                </div>              
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02">Commentaires</label>
                    <textarea class="form-control" v-model="equipForm.comments" id="" cols="30" rows="2"></textarea>
                    
                  </div>
                </div>
              </div>
              <div class="row">
                  <div class="col text-right mr-2 mb-3" v-if="!disabled">
                    <b-button :disabled="$v.equipForm.$invalid " @click="formSubmit" variant="primary" class="btn-label mr-2">
                        <i class="far fa-save text-white label-icon"></i> Enregitrer
                    </b-button>

                    <b-button  @click="cancelBtn" variant="light" class="btn-label">
                        <i class="fas fa-times-circle text-danger label-icon"></i> Annuler 
                    </b-button>
                  </div>
                </div>
            </form>
             </fieldset>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>